import * as React from "react";
import * as Styles from "./website.module.sass";
import Seo from "../components/seo";
import useWindowSize from "../utils/useWindowSize";
import { Formik, Form, Field, ErrorMessage } from "formik";
const WebsitesPage = ({ pageContext }) => {
  const dfltStatus = { status: `notsubmitted`, msg: `` };
  const { width } = useWindowSize();
  const { title, descr, link, heroimage, ...data } = pageContext;
  return (
    <>
      <Seo title={title} description={descr} />
      <div
        className={`${Styles.herobg} is-hidden-touch`}
        style={
          width > 1023
            ? {
                backgroundImage: `url(/images/${heroimage})`,
              }
            : { backgroundImage: "none" }
        }
      ></div>
      <div className={`container ${Styles.container}`}>
        <section className={`hero is-fullheight`}>
          <div className={`hero-body ${Styles.hero}`}>
            <div className={`p-10`}>
              <img
                className={`is-hidden-desktop`}
                alt={title}
                src={`/images/${heroimage}`}
                height={`100%`}
              />
              <h1 className={`title`}>{title}</h1>
              <p className={`subtitle`}>{descr}</p>
            </div>
          </div>
        </section>
        {!data.end ? (
          <>
            <div className={`tile is-ancestor m-0`}>
              <div className={`tile is-parent`}>
                <div
                  className={`tile is-child is-relative is-6 ${Styles.leftpart}`}
                >
                  <h2>Задача.</h2>
                  <p>{data.task}</p>
                </div>
              </div>
            </div>
            <div className={`tile is-ancestor m-0`}>
              <div className={`tile is-parent`}>
                <div
                  className={`tile is-child is-relative is-6 ${Styles.leftpart}`}
                >
                  <h2>Решение.</h2>
                  <p>{data.sol}</p>
                </div>
                <div
                  className={`tile is-child is-relative is-3 ${Styles.rightpart} ${Styles.tech}`}
                >
                  <h2>Технологии.</h2>
                  <ul>
                    {data.tech.map((item, index) => {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {data.design ? (
        <div className={`${Styles.white}`}>
          <div className={`container`}>
            <div className={`tile is-ancestor m-0 ${Styles.section}`}>
              <div className={`tile is-parent`}>
                <div
                  className={`tile is-child is-relative ${Styles.rightpart} ${Styles.order1}`}
                >
                  <h2>{data.end ? `Уникальность и Качество.` : `Дизайн.`}</h2>
                  <p className={`${Styles.par}`}>
                    {data.design.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          {item}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>
                <div
                  className={`tile is-child is-relative is-8 ${Styles.leftpart} ${Styles.order0}`}
                >
                  <img
                    alt={`Уникальность и Качество`}
                    src={`/images/${data.designScreen}`}
                    height={`100%`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {data.content ? (
        <div className={`${Styles.white}`}>
          <div className={`container`}>
            <div
              className={`tile is-ancestor m-0 ${Styles.section} ${Styles.white}`}
            >
              <div className={`tile is-parent`}>
                <div className={`tile is-child is-relative ${Styles.leftpart}`}>
                  <h2>
                    {data.end ? `Дизайн и Современные Тренды.` : `Контент.`}
                  </h2>
                  <p className={`${Styles.par}`}>
                    {data.content.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          {item}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>
                <div
                  className={`tile is-child is-relative is-8 ${Styles.rightpart}`}
                >
                  <img
                    alt={`Контент`}
                    src={`/images/${data.contentScreen}`}
                    height={`100%`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {data.adaptive ? (
        <div className={`container`}>
          <div className={`tile is-ancestor m-0 ${Styles.section}`}>
            <div className={`tile is-parent`}>
              <div
                className={`tile is-child is-relative ${Styles.rightpart} ${Styles.order1}`}
              >
                <h2>
                  {data.end
                    ? `Индивидуальная Кастомизация.`
                    : `Адаптивная верстка.`}
                </h2>
                <p className={`${Styles.par}`}>
                  {data.adaptive.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
              <div
                className={`tile is-child is-relative ${
                  data.end ? `is-4` : `is-8`
                } ${Styles.leftpart} ${Styles.order0}`}
              >
                <img
                  alt={`Адаптивная верстка`}
                  src={`/images/${data.adaptiveScreen}`}
                  height={`100%`}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {data.end ? (
        <div className={`container`}>
          <div className={`tile is-ancestor m-0 ${Styles.section}`}>
            <div className={`tile is-parent`}>
              <div className={`tile is-child is-relative`}>
                <h2>Заключение.</h2>
                <p className={`${Styles.par}`}>
                  {data.end.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={`container ${Styles.linksite}`}>
        <a href={link} target={`_blank`}>
          Перейти на сайт
        </a>
      </div>
      <div className={`${Styles.mainlayer}`}>
        <Formik
          initialStatus={dfltStatus}
          initialValues={{ phone: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.phone) {
              errors.phone = "Поле обязательно для заполнения";
            } else if (
              !/^[\d]{10,15}$/i.test(values.phone.replace(/[^\d]/g, ""))
            ) {
              errors.phone =
                "Неизвестный формат номера, необходимо как минимум 10 цифр";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setStatus(dfltStatus);
            let formData = new FormData();
            formData.append(`phone`, values.phone.replace(/[^\d]/g, ""));
            formData.append(`issite`, true);
            fetch("/form.php", {
              method: "POST",
              body: formData,
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                return Promise.reject({
                  status: `error`,
                  msg: `Упс! Что-то пошло не так. Попробуйте обновить страницу и попробовать ещё раз. Или воспользуйтесь кнопкой выше и позвоните нам!`,
                });
              })
              .then((response) => {
                setSubmitting(false);
                setStatus(response);
              })
              .catch((obj) => {
                setSubmitting(false);
                setStatus(obj);
              });
          }}
        >
          {({ isSubmitting, status }) =>
            status && status.status === `ok` ? (
              <div className={``}>{status.msg}</div>
            ) : (
              <>
                <h1>Хотите заказать разработку сайта?</h1>
                <h2>Оставьте номер и мы перезвоним.</h2>
                <Form>
                  <div>
                    <Field
                      placeholder={`+7 999 999 9999`}
                      className={`${Styles.field}`}
                      type="phone"
                      name="phone"
                    />
                    <ErrorMessage name="phone" component="div" />
                  </div>
                  <div>
                    <button
                      className={`button is-outlined is-radiusless ${
                        Styles.sendbutton
                      } ${isSubmitting ? `is-loading` : ``}`}
                      type="submit"
                    >
                      Отправить
                    </button>
                  </div>
                  {status.status === `error` ? (
                    <div className={``}>{status.msg}</div>
                  ) : (
                    <></>
                  )}
                  <div className={`${Styles.policylink}`}>
                    Нажимая кнопку отправить вы соглашаетесь с{" "}
                    <a
                      href={`/privacy-policy`}
                      target={`_blank`}
                      className={`${Styles.customlink}`}
                    >
                      политикой конфиденциальности
                    </a>
                    .
                  </div>
                </Form>
              </>
            )
          }
        </Formik>
      </div>
    </>
  );
};

export default WebsitesPage;
